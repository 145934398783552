import { Address } from '@wagmi/core'
import { OptionSettlementType, OptionType } from './auctions'
import {
  EthereumNetworkId,
  BitcoinNetworkId,
  CantoSupportedNetworkId,
  AnySupportedNetworkId,
  TokenID,
  ArbitrumSupportedNetworkId,
  SolanaNetworkId,
  NearNetworkId,
  OptimisimSupportedNetworkId,
  BaseNetworkId,
  AvalancheNetworkId,
  AxelarSupportedNetworkId,
} from './web3'

export const GRAPPA_DECIMALS = 6
export const ETH_DECIMALS = 18
export const USYC_DECIMALS = 6
export const CENTS_DECIMALS = 2

// constant to represent the address that is not deployed/does not exist on a network
export const DOES_NOT_EXIST = '0xDoesNotExist'

export const HN_TOKEN_UNDERLYINGS: Record<
  string,
  {
    decimals: number
    holders: []
    id: string
    name: string
    symbol: string
  }
> = {
  hnBTC: {
    decimals: 6,
    holders: [],
    id: 'btc_mainnet/bitcoin',
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  hnETH: {
    decimals: 18,
    holders: [],
    id: '0x1/ethereum',
    name: 'Ethereum',
    symbol: 'ETH',
  },
  hnSOL: {
    decimals: 9,
    holders: [],
    id: 'sol_mainnet/solana',
    name: 'Solana',
    symbol: 'SOL',
  },
  hnAVAX: {
    decimals: 9,
    holders: [],
    id: 'avax_mainnet/avax',
    name: 'Avalanche',
    symbol: 'AVAX',
  },
  hnWSTETH: {
    decimals: 18,
    holders: [],
    id: '0x1/wsteth',
    name: 'Wrapped Staked Ether',
    symbol: 'WSTETH',
  },
  hnZRX: {
    decimals: 18,
    holders: [],
    id: '0x1/zrx',
    name: '0x Protocol Token',
    symbol: 'ZRX',
  },
}

export const HARBOR_CUSTODIAN_TOKEN_IDS: Record<EthereumNetworkId, string[]> = {
  '0x1': ['0x1/hnBTC_0xcc259a57'],
  '0x5': [],
  '0xAA36A7': ['0xAA36A7/hnBTC_0xcc259a57'],
}

/** Move to a database table */
export const HARBOR_PAYOUT_RECEIPIENTS: Record<
  EthereumNetworkId,
  Record<string, Record<TokenID, string>>
> = {
  '0xAA36A7': {
    ['0x2852eBc6199288b4686009067f4b586E5A88c9F6'.toLowerCase()]: {
      '0xAA36A7/hnBTC_0xcc259a57': 'TestBTCRecipientAddress',
    },
    ['0x9c70e45d0ff1a0701f13cbd33a36ae3f08a35c63'.toLowerCase()]: {
      '0xAA36A7/hnBTC_0xcc259a57': 'tb1qsgj6sxrargp46643zfvyzz8agsczhgtvzcs66j',
    },
  },
  '0x1': {
    ['0xa87a233e8a7d8951fF790A2e39738086cb5f71b7'.toLowerCase()]: {
      '0x1/hnBTC_0xcc259a57': 'bc1qnqhp32meuj9k7lmf58fntj86uu3epzcava2ccm',
    },
    ['0x8603ed3e4643539c7d4007a04d503020336c81b0'.toLowerCase()]: {
      '0x1/hnBTC_0xcc259a57': 'bc1qs6y4yzt7gltzetv3nhf6wkfw3ma36mj2t53m56',
    },
  },
  '0x5': {},
}

export const HARBOR_CUSTODIAN_RECIPIENTS: Record<EthereumNetworkId, Record<string, string>> = {
  '0x5': {},
  '0x1': {
    hnBTC_0xcc259a57: '37VdHEbDodtYhK3BNJ3XK5dDue2mGMDS1q',
  },
  '0xAA36A7': {
    hnBTC_0xcc259a57: 'tb1qsgj6sxrargp46643zfvyzz8agsczhgtvzcs66j',
  },
}

export const WRAPPED_STETH_ADDRESSES: Record<EthereumNetworkId, Address> = {
  '0x1': '0xe1411AdCfF539eFdE126db8866e6306F50516a50',
  '0x5': '0xC5660B7d57dfFfd02730E7cF71dC481905A1454d',
  '0xAA36A7': '0x7AaaA3d35497291e85f9B3673498AFc8Fb838147',
} as const

export const STETH_ADDRESSES: Record<EthereumNetworkId, Address> = {
  '0x1': '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
  '0xAA36A7': '0x3e3FE7dBc6B4C189E7128855dD526361c49b40Af',
  '0x5': DOES_NOT_EXIST,
} as const

export const STETH_WITHDRAW_QUEUE_ADDRESSES: Record<EthereumNetworkId, Address> = {
  '0x1': '0x889edC2eDab5f40e902b864aD4d7AdE8E412F9B1',
  '0xAA36A7': '0x1583C7b3f4C3B008720E6BcE5726336b0aB25fdd',
  '0x5': DOES_NOT_EXIST,
} as const

export const ZRX_ADDRESSES: Record<EthereumNetworkId, Address> = {
  '0x1': '0x32b2A50a93cE946fB447ea5cFF37e3571e2aB8E0',
  '0x5': '0x94c5b8fBd647F93ab9446DaED787A7716afEb199',
  '0xAA36A7': '0x0b919b9A70A4F55B5671Ec072f5b10521900B120',
} as const

export const ADA_ADDRESSES: Record<EthereumNetworkId, Address> = {
  '0x1': '0x6b58F317fe6aA7279B4b736f9080d657e5BFd5fE',
  '0x5': '0x5b71f673b295f7F387C717F7dC4D0788b76ea214',
  '0xAA36A7': '0x393481E14961c700d16e571396b966E3f4Ca6EC8',
} as const

export const AVAX_ADDRESSES: Record<EthereumNetworkId, Address> = {
  '0x1': '0x57aFa1235a841c66641FE8be6a0CF6f1980E54cD',
  '0x5': DOES_NOT_EXIST,
  '0xAA36A7': DOES_NOT_EXIST,
} as const

export type SupportedPeggedFundingToken = 'USDC' | 'USDT' | 'PYUSD'
export type SupportedNonPeggedFundingToken = 'ETH' | 'BTC' | 'SOL'
export type SupportedFundingToken = SupportedPeggedFundingToken | SupportedNonPeggedFundingToken

export const SUPPORTED_PEGGED_FUNDING_TOKENS: SupportedPeggedFundingToken[] = [
  'USDC',
  'USDT',
  'PYUSD',
]
export const SUPPORTED_NON_PEGGED_FUNDING_TOKENS: SupportedNonPeggedFundingToken[] = [
  'ETH',
  'BTC',
  'SOL',
]
export const SUPPORTED_FUNDING_TOKEN: SupportedFundingToken[] = [
  ...SUPPORTED_PEGGED_FUNDING_TOKENS,
  ...SUPPORTED_NON_PEGGED_FUNDING_TOKENS,
]

/*
  Maps to the Subgraph token addresses or Hashnote wrapped token equivalent (e.g. ETH -> hnETH) if applicable
*/
export const SUBGRAPH_TOKEN_ADDRESS: Record<
  EthereumNetworkId,
  Record<SupportedNonPeggedFundingToken, Address>
> = {
  '0x1': {
    ETH: '0x88e6998cec706e80ca3f38d635e9ee503f50d83d',
    BTC: '0xbad11967371814054d505642a733ab6a83b7c2db',
    SOL: '0xd31a59c85ae9d8edefec411d448f90841571b89c',
  },
  '0xAA36A7': {
    ETH: '0x4fa31a82a479f3238d06fe1b29ab14c8783149a1',
    BTC: '0xbcc728bf13718cf5dd1945fc1ffd8aae2ceb4d0f',
    SOL: '0xd31a59c85ae9d8edefec411d448f90841571b89c',
  },
  '0x5': {
    ETH: DOES_NOT_EXIST,
    BTC: DOES_NOT_EXIST,
    SOL: DOES_NOT_EXIST,
  },
}

export const HASHNOTE_ENVS = ['dev', 'prod'] as const
export type HashnoteEnv = (typeof HASHNOTE_ENVS)[number]

export const SUPPORTED_NETWORKS = ['0x1', '0x5', '0xAA36A7'] as const

export type USYCCollateral = 'USDC' | 'PYUSD' | 'HNUSD'

export const USYC_COLLATERALS: USYCCollateral[] = ['PYUSD', 'USDC', 'HNUSD']
export const USYC_COLLATERALS_BY_CHAIN: Record<
  EthereumNetworkId | BaseNetworkId,
  USYCCollateral[]
> = {
  '0x1': ['PYUSD', 'USDC'],
  '0x5': ['PYUSD', 'USDC'],
  '0xAA36A7': ['PYUSD', 'USDC'],
  base_sepolia: ['USDC', 'HNUSD'],
  base_mainnet: ['USDC', 'HNUSD'],
}

export const PYUSD_INTEREST_PAYER_VAULT: Record<EthereumNetworkId, `${number}`> = {
  '0x1': '100',
  '0x5': '99999999999999', // does not exist, not used
  '0xAA36A7': '48',
} as const

export const TELLER_ADDRESS: Record<
  USYCCollateral,
  Record<EthereumNetworkId | BaseNetworkId, Address>
> = {
  HNUSD: {
    '0x1': DOES_NOT_EXIST,
    '0x5': DOES_NOT_EXIST,
    '0xAA36A7': DOES_NOT_EXIST,
    base_sepolia: '0x3B7256048f64a26DC96A935aC0E833e37462200a',
    base_mainnet: DOES_NOT_EXIST,
  },
  PYUSD: {
    '0x5': DOES_NOT_EXIST,
    '0x1': '0x0a5ea26fdd38cf2acb06dc64198374c337879dab',
    '0xAA36A7': '0x8C5d21F2DA253a117E8B89108be8FE781583C1dF',
    base_sepolia: DOES_NOT_EXIST,
    base_mainnet: DOES_NOT_EXIST,
  },
  USDC: {
    '0x5': '0xE182E029462c848cfa0AdD4ceC2563306FA3a787',
    '0x1': '0x5C73E1cfdD85b7f1d608F7F7736fC8C653513B7A',
    '0xAA36A7': '0xbb0524426bc1d13dAB721DB69D86374FC6BaCDba',
    base_sepolia: '0xB564cE0A049977473865B8C2B16537D7b4aebe47',
    base_mainnet: '0xAE133456840000C7ebe16b943054Cb303A162438',
  },
} as const

export const BYTES_ZERO = '0x0000000000000000000000000000000000000000000000000000000000000000'
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000'

export const SDYF_ADDRESS: Record<EthereumNetworkId, Address> = {
  '0x5': '0x8c1E7aB380bcBb4ed55A9402fb87F826C1ac5c82', // dave's goerli wallet
  '0x1': '0xF2B26fDc5fA96094b3ed26DCB89C520Cb63CCfea',
  '0xAA36A7': '0x7f47785317F1EdBe737Ee731Be431CE5e639dd2B',
} as const

export const CD20_ADDRESS: Record<EthereumNetworkId, Address> = {
  '0x1': '0xBB30724D3EAAF10F6c5b0C683952598DB3D3e61D',
  '0x5': DOES_NOT_EXIST,
  '0xAA36A7': '0x2555cE80882ac94181B1aDCb54AD31416E1EE77d',
}

export const USYC_ADDRESS: Record<
  EthereumNetworkId | CantoSupportedNetworkId | BaseNetworkId,
  Address
> = {
  '0x1': '0x136471a34f6ef19fE571EFFC1CA711fdb8E49f2b',
  '0x5': '0xb265020C1E8841b0e16e2f8E8d21D2B45ab2df42',
  '7700': '0xFb8255f0De21AcEBf490F1DF6F0BDd48CC1df03B',
  '7701': '0xd3D41C08a7e14129bF6Ec9A32697322C70af7E5a',
  '0xAA36A7': '0x38D3A3f8717F4DB1CcB4Ad7D8C755919440848A3',
  base_sepolia: '0x024E76D7efD2160cd9f1966B49D640f6F6fAA499',
  base_mainnet: '0xeeE7aF832440884d2b693B4193FA2ec26A48C7d3',
} as const

export const TELEPORT_ADDRESS: Record<EthereumNetworkId | CantoSupportedNetworkId, Address> = {
  '0x1': '0xc0778b47bD62b9EDd2D1632c268A035c6ED79C65',
  '0x5': '0x6eA5E08aF2aed210A1ad764eF94E46F78e229Fd7',
  '7700': '0x895a15b15BAd3374F4580800B5771da9cd8a9c8A',
  '7701': '0x0d0aa6c855268d9Dd5519e0cd1f6AF82fbCF971c',
  '0xAA36A7': DOES_NOT_EXIST,
} as const

export const PYUSD_ADDRESS: Record<EthereumNetworkId, Address | null> = {
  '0x1': '0x6c3ea9036406852006290770bedfcaba0e23a0e8',
  '0x5': DOES_NOT_EXIST,
  '0xAA36A7': '0xCaC524BcA292aaade2DF8A05cC58F0a65B1B3bB9',
}

export const HNUSD_ADDRESS: Record<BaseNetworkId, Address> = {
  base_sepolia: '0x87967CeB1750972Db9f41696C9274b28A3D5c88C',
  base_mainnet: '0xED8Be5F4DE1C45B7402e07C61C03828Ed21346c3',
}

export const USDC_ADDRESS: Record<EthereumNetworkId | BaseNetworkId, Address> = {
  '0xAA36A7': '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
  '0x5': '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  '0x1': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  base_sepolia: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
  base_mainnet: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
} as const

export const WETH_ADDRESSES: Record<EthereumNetworkId, Address> = {
  '0xAA36A7': '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
  '0x5': '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
  '0x1': '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
} as const

export const US_FEEDER_ADDRESS: Record<EthereumNetworkId, Address> = {
  '0xAA36A7': DOES_NOT_EXIST,
  '0x5': DOES_NOT_EXIST,
  '0x1': '0xCAB8ebbb8E9fD0857C8d82C30364e928d3BD88DE',
} as const

export const INTERNATIONAL_FEEDER_ADDRESS: Record<EthereumNetworkId, Address> = {
  '0xAA36A7': DOES_NOT_EXIST,
  '0x5': DOES_NOT_EXIST,
  '0x1': '0x381812EA0ab537f8D8f4A21A138674c0227e9526',
} as const

export const SIMPLE_SETTLEMENT_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': '0xE37766B9D2011ff64f228fa2E0663911596d717C',
  '0x5': '0xB5A924147635D271abDD650aAc6fE04234367e29',
  '0x1': '0x1e3F3CfbBfdC6366bbc67CCfd20CdD8cCf442c19',
} as const

export const FEE_RECIPIENT: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': '0xe287AC3C71F2EFfCc77D02d628EAbe9F6eE8515a',
  '0x5': '0xe287AC3C71F2EFfCc77D02d628EAbe9F6eE8515a',
  '0x1': '0xed96e247655361031aEE6514cD1b89C7141b59D5',
} as const

/**
 * Must use these for verifyingContract field when signing bids
 */
export const SIMPLE_SETTLEMENT_IMPLEMENTATION_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> =
  {
    '0xAA36A7': '0x374e787ef8aC7B49C27Cf05Cc7EcC9E3913a05fd',
    '0x5': '0x72630839caA12A0441C0804C54661b137D9951F6',
    '0x1': '0xCCCcA6751AD6285b4cD006552A9ec21BaEdA35d0',
  } as const

export const GRAPPA_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': '0x3748B3b3BAffBCbcD34cA9FF92716629a088558c',
  '0x1': '0xe5fC332620C8Ba031d697Bd45f377589f633A255',
  '0x5': '0x32076d5756edB4f1660946eeAA8d954e43141990',
} as const

export const POMACE_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': '0xBffcf4487728ADFF960c4Ac6565B257D5787E26F',
  '0x1': '0x8Cadc832040bc66a0Aeef9bF6b6D1bD7bcC5ACDb',
  '0x5': '0xdeC90d52defCaa4B95f2fbC798EBd7a680b23131',
} as const

export const CHAINLINK_ORACLE_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': '0xe15e96F69400Dc68a0354c1C73aa93f4BC7FB602',
  '0x1': '0x9bcE7eB7F44FCa5b97fbc668787990f1a7861DF6',
  '0x5': '0xC3bD837BBc8c64691C306cEA05F2C4ac631fa809',
} as const

export const CHAINLINK_AGGREGATOR_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': '0x38D3A3f8717F4DB1CcB4Ad7D8C755919440848A3',
  '0x1': '0x4c48bcb2160F8e0aDbf9D4F3B034f1e36d1f8b3e',
  '0x5': '0x06BE409e34fC73BF23f2276D191Cc80e479334a2',
} as const

export const CD20_AGGREGATOR_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': '0x6B4dbc1F870A5A30438EdE380BE9221A44aa20dd',
  '0x1': '0x1A886FAFE770A32812972c5D5632D0b4A6156Fd0',
  '0x5': DOES_NOT_EXIST,
} as const

export const ARBITRUM_MAINNET_AGGREGATOR_ADDRESSES: Record<string, `0x${string}`> = {
  ADA: '0xD9f615A9b820225edbA2d821c4A696a0924051c6',
  AVAX: '0x8bf61728eeDCE2F32c456454d87B5d6eD6150208',
}

export const POSITION_PAUSER_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': DOES_NOT_EXIST,
  '0x1': '0x2cf8d5089D3CC289B369BFf29646d4070459C66b',
  '0x5': '0xa72aBc88384ba79982B6ae1f70E5eC20c4eEFDdB',
} as const

export const VAULT_REGISTRAR_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0xAA36A7': DOES_NOT_EXIST,
  '0x1': '0x04F793A709fF868760CC9bA88a7B6dcBe0a4ee04',
  '0x5': '0x140da7aFBf5ccDd796785ED3110c57db1115485b',
} as const

export const USYC_AGGREGATOR_ADDRESSES: Record<
  EthereumNetworkId | CantoSupportedNetworkId | BaseNetworkId,
  `0x${string}`
> = {
  '0x1': '0x4c48bcb2160F8e0aDbf9D4F3B034f1e36d1f8b3e',
  '0x5': '0x06BE409e34fC73BF23f2276D191Cc80e479334a2',
  '7700': '0x1d18c02bC80b1921255E71cF2939C03258d75470',
  '7701': '0x35b96d80C72f873bACc44A1fACfb1f5fac064f1a',
  '0xAA36A7': '0x35b96d80C72f873bACc44A1fACfb1f5fac064f1a',
  base_mainnet: DOES_NOT_EXIST,
  base_sepolia: DOES_NOT_EXIST,
} as const

export const NEW_USYC_AGGREGATOR_ADDRESSES: Record<
  EthereumNetworkId | CantoSupportedNetworkId | BaseNetworkId,
  `0x${string}`
> = {
  '0x1': '0x74f2199AEb743f68f05943e5715A33EaF2b61f53',
  '0x5': DOES_NOT_EXIST,
  '7700': DOES_NOT_EXIST,
  '7701': DOES_NOT_EXIST,
  '0xAA36A7': '0x48c9E02E09d03E448673166d29691ECdEf56526F',
  base_mainnet: '0x227C60Ac0A0574801B340a7D408A4bfD64A713d2',
  base_sepolia: '0x11c0f548b8ef907d1287c7aa1b9fec3f9c393c16',
} as const

export const AXELAR_MESSENGER_ADDRESSES: Record<AxelarSupportedNetworkId, `0x${string}`> = {
  '0x1': '0x35d95e93E8aFCAaa3046B9E5926E92B46AAd9b11',
  '0x5': DOES_NOT_EXIST,
  '0xAA36A7': '0x09125faf1A5FB0178BA8238de365E1Ef24Be8dEF',
  base_sepolia: '0x109656Aba6F175c634c63C9874f29CeAAAB8E606',
  base_mainnet: '0x10022BE24d6fbbF7Ce321361E3371f0A05754Ed0',
} as const

export const PRICE_REPORTER_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0x1': '0x9fde717a21c5b272B8956d3AA0c3551E1FFd23D7',
  '0x5': DOES_NOT_EXIST,
  '0xAA36A7': '0x4398E63aB4791E6281cB0a0948f390f493c91086',
} as const

export const AXELAR_CHAIN_TO_NETWORK_ID: Record<string, EthereumNetworkId | BaseNetworkId> = {
  Ethereum: '0x1',
  goerli: '0x5',
  'ethereum-sepolia': '0xAA36A7',
  'base-sepolia': 'base_sepolia',
  base: 'base_mainnet',
} as const

export const CHAIN_TO_REF_DB_BLOCKCHAIN: Record<AxelarSupportedNetworkId, string> = {
  '0x1': 'eth_mainnet',
  '0x5': 'goerli',
  '0xAA36A7': 'eth_sepolia',
  base_sepolia: 'base_sepolia',
  base_mainnet: 'base_mainnet',
} as const

export const OPTION_TYPE_TO_ONCHAIN_ENUM: Record<
  OptionSettlementType,
  Record<OptionType, number>
> = {
  X: {
    C: 1,
    P: 0,
  },
  O: {
    C: 2,
    P: 0,
  },
} as const

export const ONCHAIN_OPTION_ENUM_TO_OPTION_TYPE: Record<
  OptionSettlementType,
  Record<string, OptionType>
> = {
  X: {
    '1': 'C',
    '0': 'P',
  },
  O: {
    '2': 'C',
    '0': 'P',
  },
} as const

export const CROSS_MARGIN_ENGINE_ADDRESSES: Record<
  OptionSettlementType,
  Record<EthereumNetworkId, `0x${string}`>
> = {
  X: {
    // physical
    '0x1': '0x341C281d11677795F1192bF1f7438666909841c8',
    '0x5': '0xD8C229BadFDf7b25BCb335C917598D3dd99E81Ed',
    '0xAA36A7': '0xc1574ec6974dbb5B33212CaFF15e604dB2283289',
  },
  O: {
    // cash
    '0x1': '0x9c742Aef14CC875C49f52bBD4473B35beBAD26Ae',
    '0x5': '0xeF54651432c4c6b093f0d06b91be8a9965A15dF4',
    '0xAA36A7': '0x1eE28580fE2377726017fC910513a37b792dCC9d',
  },
} as const

export const CROSS_MARGIN_ENGINE_SETTLEMENT_TYPE: Record<
  EthereumNetworkId,
  Record<Address, OptionSettlementType>
> = {
  '0x1': {
    ['0x341C281d11677795F1192bF1f7438666909841c8'.toLowerCase()]: 'X',
    ['0x9c742Aef14CC875C49f52bBD4473B35beBAD26Ae'.toLowerCase()]: 'O',
  },
  '0x5': {
    ['0xD8C229BadFDf7b25BCb335C917598D3dd99E81Ed'.toLowerCase()]: 'X',
    ['0xeF54651432c4c6b093f0d06b91be8a9965A15dF4'.toLowerCase()]: 'O',
  },
  '0xAA36A7': {
    ['0xc1574ec6974dbb5B33212CaFF15e604dB2283289'.toLowerCase()]: 'X',
    ['0x1eE28580fE2377726017fC910513a37b792dCC9d'.toLowerCase()]: 'O',
  },
} as const

export const HASHNOTE_VAULT_MANAGER_FB_VAULT_ID: Record<EthereumNetworkId, string> = {
  '0x1': '5',
  '0x5': '1',
  '0xAA36A7': '1',
} as const

export const HASHNOTE_WHITELIST_MANAGER_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0x1': '0xCEDAfE1EaA250DA15c434A54ece8BA1702876e3A',
  '0x5': '0xcCEDF13cBEaF1014f236e5C67992B08a4dC40C46',
  '0xAA36A7': '0xA1B21377920c819B0320C6Fbe3BAea8e4bBb3F8F',
} as const

export const HASHNOTE_PPRA_VAULT_ADDRESSES: Record<EthereumNetworkId, `0x${string}`> = {
  '0x1': '0x2C202d6a890BC8bb215ceAE40d2ff12F5D323e12',
  '0x5': '0xF319ABA0174723CebAD143ed3a1627D31A92f154',
  '0xAA36A7': '0x6C9516348bdd50441E71E64D2A8B10CD18924B45',
} as const

export const ROLES_AUTHORITY_ADDRESSES: Record<AnySupportedNetworkId, `0x${string}`> = {
  '0x1': '0x470f3b37B9B20E13b0A2a5965Df6bD3f9640DFB4',
  '0x5': '0x1E8Bc05C7a4A20B161B9B43B19Cde6c391D90bce',
  '0xAA36A7': '0xFA4400c1B9AC496d9578B4B6507295A5aaD29EE7',
} as const

export const MARKET_MAKERS_FOR_REPORTS: Record<AnySupportedNetworkId, `0x${string}`[]> = {
  '0x1': [
    '0x8603ed3e4643539c7d4007a04d503020336c81b0',
    '0x3396c5ade0266f1bd93911f9acb9413333a735da',
    '0xa87a233e8a7d8951ff790a2e39738086cb5f71b7',
    '0xc96665ea03ef99ccfd9c3ec61740678bb8bb4e70',
    '0xadccfad41a83c1bc3228c8d87aed71e6a5a604b9',
  ],
  '0x5': [],
  '0xAA36A7': [],
}

export const NEAR_NETWORK_ID_RPC_URL_MAP: Record<NearNetworkId, string> = {
  mainnet: 'https://rpc.mainnet.near.org',
  testnet: 'https://rpc.testnet.near.org',
}

export const NETWORK_ID_RPC_HTTP_URL_MAP: Record<
  EthereumNetworkId | CantoSupportedNetworkId,
  string
> = {
  '0x1': `https://eth-mainnet.g.alchemy.com/v2/LSi9R0ntQ45UQPZc1h1vjqFuSGMSqsAD`,
  '0x5': `https://eth-goerli.g.alchemy.com/v2/-5FJl2Rufrl8_4EhBtbZARpbQYoMTiwE`,
  '0xAA36A7': `https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W`,
  '7700': `https://canto-rpc.ansybl.io`,
  '7701': `https://canto-testnet.plexnode.wtf`,
} as const

export const ETHEREUM_TO_CANTO_CHAIN: Record<number, number> = {
  1: 7700,
  5: 7701,
  11155111: 7701,
}

export const FIREBLOCKS_BASE_ASSET_TO_NETWORK: Record<string, string> = {
  ETH_TEST5: 'ETH_SEPOLIA',
  ETH: 'ETH_MAINNET',
  BTC_TEST: 'BTC_TESTNET',
  BTC: 'BTC_MAINNET',
  SOL: 'SOL_MAINNET',
} as const

export const FIREBLOCKS_ASSET_IDS: Record<
  EthereumNetworkId | BitcoinNetworkId | SolanaNetworkId | AvalancheNetworkId,
  Record<string, string>
> = {
  '0x5': {
    ETH: 'ETH_TEST3',
    USDC: 'USDC_TEST3',
    USYC: 'SDYC_ETH_TEST3_9ITH',
    WETH: 'WETH_TEST3',
    WBTC: 'WBTC_ETH_TEST3_YBJ6',
  },
  '0xAA36A7': {
    ETH: 'ETH_TEST5',
    USDC: 'USDC_ETH_TEST5_0GER',
    USYC: 'USYC_ETH_TEST5_KKW4',
    WETH: 'WETH_ETH_TEST5_A5VG',
    WBTC: 'WBTC_ETH_TEST5_TAYO',
    PYUSD: 'PYUSD_ETH_TEST5_ERQB',
  },
  '0x1': {
    ETH: 'ETH',
    STETH: 'STETH_ETH',
    USDC: 'USDC',
    USDT: 'USDT_ERC20',
    USYC: 'USYC_ETH_OI7L',
    WETH: 'WETH',
    WBTC: 'WBTC',
    PYUSD: 'PYUSD_ETH',
  },
  btc_testnet: {
    BTC: 'BTC_TEST',
  },
  btc_mainnet: {
    BTC: 'BTC',
  },
  sol_mainnet: {
    SOL: 'SOL',
    USDC: 'SOL_USDC_PTHX',
  },
  avax_mainnet: {
    AVAX: 'AVAX',
  },
} as const

export const FIREBLOCKS_TOKEN_OWNER_IDS: Record<EthereumNetworkId, string> = {
  '0x1': '19',
  '0x5': '34',
  '0xAA36A7': '34',
} as const

/**
 * Can be found via the URL in the Fireblocks UI
 */
export const FIREBLOCKS_VAULT_AUCTIONEER: Record<EthereumNetworkId, string> = {
  '0x1': '16',
  '0x5': '26',
  '0xAA36A7': '26',
} as const

export type FireblocksOperation = 'DEPOSIT' | 'WITHDRAW' | 'INTERNAL' | 'OTHER'
export type FireblocksStatus = 'CONFIRMING'

/**
 * TODO: Deprecate the prices subgraph, it's not being used
 */
export const PRICES_SUBGRAPH_URLS: Record<EthereumNetworkId, string> = {
  '0x1': 'https://api.thegraph.com/subgraphs/name/hashnotefi/prices',
  '0x5': 'https://api.thegraph.com/subgraphs/name/hashnotefi/prices-goerli',
  '0xAA36A7': 'DoesNotExist',
} as const

/**
 * Action types for cash(Grappa) cross margin engine
 */
export enum GrappaActionType {
  AddCollateral,
  RemoveCollateral,
  MintShort,
  BurnShort,
  MergeOptionToken,
  SplitOptionToken,
  AddLong,
  RemoveLong,
  SettleAccount,
  MintShortIntoAccount,
  TransferCollateral,
  TransferLong,
  TransferShort,
  BurnShortInAccount,
}

/**
 * Action types for the physical(Pomace) cross margine engine
 */
export enum PomaceActionType {
  AddCollateral,
  RemoveCollateral,
  MintShort,
  BurnShort,
  AddLong,
  RemoveLong,
  ExerciseToken,
  SettleAccount,
  // actions that influence more than one subAccounts:
  // These actions are defined in "OptionTransferable"
  MintShortIntoAccount, // increase short (debt) position in one subAccount, increase long token directly to another subAccount
  TransferCollateral, // transfer collateral directly to another subAccount
  TransferLong, // transfer long directly to another subAccount
  TransferShort, // transfer short directly to another subAccount
  BurnShortInAccount,
}

export const IMPLEMENTATION_SLOT =
  '0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc'

export const CHAIN_TO_FRIENDLY_NAME: Record<
  EthereumNetworkId | BitcoinNetworkId | SolanaNetworkId | BaseNetworkId,
  string
> = {
  '0x1': 'Ethereum',
  '0x5': 'Ethereum Goerli',
  '0xAA36A7': 'Ethereum Sepolia',
  btc_mainnet: 'Bitcoin',
  btc_testnet: 'Bitcoin Testnet',
  sol_mainnet: 'Solana',
  base_sepolia: 'Base Sepolia',
  base_mainnet: 'Base',
} as const

export const RPC_URLS: Record<
  HashnoteEnv,
  Record<
    | BaseNetworkId
    | EthereumNetworkId
    | CantoSupportedNetworkId
    | ArbitrumSupportedNetworkId
    | OptimisimSupportedNetworkId,
    {
      usycPortalPrimary: string
      usycPortalFallback: string
      terminalPrimary: string
      terminalFallback: string
      servicesPrimary: string
      servicesFallback: string
      conductorPrimary: string
      conductorFallback: string
    }
  >
> = {
  dev: {
    '0x1': {
      usycPortalPrimary: 'https://eth-mainnet.g.alchemy.com/v2/B-_u-3vLYb0xby9yAy59roOaxlKyUqax',
      usycPortalFallback: 'https://mainnet.infura.io/v3/42c4baaf717c4378a1ffcc3e9a025544',
      terminalPrimary: 'https://eth-mainnet.g.alchemy.com/v2/kWrg5UZlgudmvcrG2pX0trauJfJxvyXG',
      terminalFallback: 'https://mainnet.infura.io/v3/98558eb79de246cba7bfd51cc0a3a4a2',
      servicesPrimary: 'https://eth-mainnet.g.alchemy.com/v2/8eJ5PwXBBVUyg-ghx7QnffRTTYCVDs3V',
      servicesFallback: 'https://mainnet.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
      conductorPrimary: 'https://eth-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://mainnet.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
    },
    '0x5': {
      usycPortalPrimary: 'https://eth-goerli.g.alchemy.com/v2/zWnxQFBsGqwP8Xkv1kkYfZOdmukcxGVA',
      usycPortalFallback: 'https://goerli.infura.io/v3/42c4baaf717c4378a1ffcc3e9a025544',
      terminalPrimary: 'https://eth-goerli.g.alchemy.com/v2/SZshj8UEotoH3X9RuG11txoDd2CL4PE_',
      terminalFallback: 'https://goerli.infura.io/v3/98558eb79de246cba7bfd51cc0a3a4a2',
      servicesPrimary: 'https://eth-goerli.g.alchemy.com/v2/zH-VjJJ8Nn_Dz_p4IpcKfm4_x91-QFoM',
      servicesFallback: 'https://goerli.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
      conductorPrimary: 'https://eth-goerli.g.alchemy.com/v2/sQwL9yu5oYfGVt5c5vwLty_QbAcKmFNu',
      conductorFallback: 'https://goerli.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
    },
    '0xAA36A7': {
      // these are all the same for now
      usycPortalPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      usycPortalFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      terminalPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      terminalFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      servicesPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      servicesFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      conductorPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      conductorFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
    },
    '7700': {
      usycPortalPrimary: 'https://canto-rpc.ansybl.io',
      usycPortalFallback: 'https://canto-rpc.ansybl.io',
      terminalPrimary: 'https://canto-rpc.ansybl.io',
      terminalFallback: 'https://canto-rpc.ansybl.io',
      servicesPrimary: 'https://canto-rpc.ansybl.io',
      servicesFallback: 'https://canto-rpc.ansybl.io',
      conductorPrimary: 'https://canto-rpc.ansybl.io',
      conductorFallback: 'https://canto-rpc.ansybl.io',
    },
    '7701': {
      usycPortalPrimary: 'https://canto-testnet.plexnode.wtf',
      usycPortalFallback: 'https://canto-testnet.plexnode.wtf',
      terminalPrimary: 'https://canto-testnet.plexnode.wtf',
      terminalFallback: 'https://canto-testnet.plexnode.wtf',
      servicesPrimary: 'https://canto-testnet.plexnode.wtf',
      servicesFallback: 'https://canto-testnet.plexnode.wtf',
      conductorPrimary: 'https://canto-testnet.plexnode.wtf',
      conductorFallback: 'https://canto-testnet.plexnode.wtf',
    },
    '0xA4B1': {
      usycPortalPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      usycPortalFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      terminalPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      terminalFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      servicesPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      servicesFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      conductorPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      conductorFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
    },
    '0xAA37DC': {
      usycPortalPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      usycPortalFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
    },
    base_sepolia: {
      usycPortalPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      usycPortalFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
    },
    base_mainnet: {
      usycPortalPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      usycPortalFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
    },
  },
  prod: {
    '0x1': {
      usycPortalPrimary: 'https://eth-mainnet.g.alchemy.com/v2/mFWHL8aQGXuRWHAygLftAH1XWgGxY8Xg',
      usycPortalFallback: 'https://mainnet.infura.io/v3/42c4baaf717c4378a1ffcc3e9a025544',
      terminalPrimary: 'https://eth-mainnet.g.alchemy.com/v2/C4vzs4rMalSuiJywh9tQdbArn-h-hJmy',
      terminalFallback: 'https://mainnet.infura.io/v3/98558eb79de246cba7bfd51cc0a3a4a2',
      servicesPrimary: 'https://eth-mainnet.g.alchemy.com/v2/LSi9R0ntQ45UQPZc1h1vjqFuSGMSqsAD',
      servicesFallback: 'https://mainnet.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
      conductorPrimary: 'https://eth-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://mainnet.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
    },
    '0x5': {
      usycPortalPrimary: 'https://eth-goerli.g.alchemy.com/v2/x8nw-maRD5joaRTGm-LnH6246ubcng3h',
      usycPortalFallback: 'https://goerli.infura.io/v3/42c4baaf717c4378a1ffcc3e9a025544',
      terminalPrimary: 'https://eth-goerli.g.alchemy.com/v2/Y_dpaLAmfy8E_sS5vECpNUiEjoAWAzqO',
      terminalFallback: 'https://goerli.infura.io/v3/98558eb79de246cba7bfd51cc0a3a4a2',
      servicesPrimary: 'https://eth-goerli.g.alchemy.com/v2/-5FJl2Rufrl8_4EhBtbZARpbQYoMTiwE',
      servicesFallback: 'https://goerli.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
      conductorPrimary: 'https://eth-goerli.g.alchemy.com/v2/sQwL9yu5oYfGVt5c5vwLty_QbAcKmFNu',
      conductorFallback: 'https://goerli.infura.io/v3/a7ea878e709d4172a52a5d480cbfe6e0',
    },
    '0xAA36A7': {
      // these are all the same for now
      usycPortalPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      usycPortalFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      terminalPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      terminalFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      servicesPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      servicesFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      conductorPrimary: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
      conductorFallback: 'https://eth-sepolia.g.alchemy.com/v2/IgDqua1R3cN6qJpH-77eaI-6osf6n53W',
    },
    '7700': {
      usycPortalPrimary: 'https://canto-rpc.ansybl.io',
      usycPortalFallback: 'https://canto-rpc.ansybl.io',
      terminalPrimary: 'https://canto-rpc.ansybl.io',
      terminalFallback: 'https://canto-rpc.ansybl.io',
      servicesPrimary: 'https://canto-rpc.ansybl.io',
      servicesFallback: 'https://canto-rpc.ansybl.io',
      conductorPrimary: 'https://canto-rpc.ansybl.io',
      conductorFallback: 'https://canto-rpc.ansybl.io',
    },
    '7701': {
      usycPortalPrimary: 'https://canto-testnet.plexnode.wtf',
      usycPortalFallback: 'https://canto-testnet.plexnode.wtf',
      terminalPrimary: 'https://canto-testnet.plexnode.wtf',
      terminalFallback: 'https://canto-testnet.plexnode.wtf',
      servicesPrimary: 'https://canto-testnet.plexnode.wtf',
      servicesFallback: 'https://canto-testnet.plexnode.wtf',
      conductorPrimary: 'https://canto-testnet.plexnode.wtf',
      conductorFallback: 'https://canto-testnet.plexnode.wtf',
    },
    '0xA4B1': {
      usycPortalPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      usycPortalFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      terminalPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      terminalFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      servicesPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      servicesFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      conductorPrimary: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
      conductorFallback: 'https://arb-mainnet.g.alchemy.com/v2/B0FGmfsdeXnqMLnllOn15ri-zzCrNx4x',
    },
    '0xAA37DC': {
      usycPortalPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      usycPortalFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorPrimary: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://opt-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
    },
    base_sepolia: {
      usycPortalPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      usycPortalFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorPrimary: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://base-sepolia.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
    },
    base_mainnet: {
      usycPortalPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      usycPortalFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      terminalFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      servicesFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorPrimary: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
      conductorFallback: 'https://base-mainnet.g.alchemy.com/v2/7FdbJ4D9t-bkxdXC6VZ6CaxH_hnYn2Ow',
    },
  },
} as const

export const IGNORE_VAULTS_FOR_REPORTS = [
  '0x635d7b3e87195d8F70D8898df20AA87a19a0a286'.toLowerCase(),
  '0xc007f53093BD487A482d3C43dB2B3e1F1D58236a'.toLowerCase(),
] as const

export const USD_SUBGRAPH_PRECISION = 8 as const

export type WalletClassification = 'internal_managed' | 'external_source' | 'external_managed'
export const WALLET_CLASSIFICATIONS: WalletClassification[] = [
  'internal_managed',
  'external_source',
  'external_managed',
]

export const ROLES_NAMES = {
  0: 'Investor_MFFeederDomestic',
  1: 'Investor_MFFeederInternational',
  2: 'Investor_SDYFDomestic',
  3: 'Investor_SDYFInternational',
  4: 'Investor_LOFDomestic',
  5: 'Investor_LOFInternational',
  6: 'Investor_Reserve1',
  7: 'Investor_Reserve2',
  8: 'Investor_Reserve3',
  9: 'Investor_Reserve4',
  10: 'Investor_Reserve5',
  11: 'Custodian_Centralized',
  12: 'Custodian_Decentralized',
  13: 'System_FundAdmin',
  14: 'System_Token',
  15: 'System_Vault',
  16: 'System_Auction',
  17: 'System_Teller',
  18: 'System_Oracle',
  19: 'System_MarginEngine',
  20: 'LiquidityProvider_Options',
  21: 'LiquidityProvider_Spot',
  22: 'System_Entitlements',
  23: 'System_Messenger',
}

export type DateWithoutTimestamp = `${number}-${number}-${number}` | string

export const BANK_HOLIDAYS: DateWithoutTimestamp[] = [
  '2023-01-01', // New Year's Day
  '2023-01-16', // Martin Luther King Jr. Day
  '2023-02-20', // Presidents Day
  '2023-05-29', // Memorial Day
  '2023-06-19', // Juneteenth
  '2023-07-04', // Independence Day
  '2023-09-04', // Labor Day
  '2023-10-09', // Columbus Day
  '2023-11-10', // Veterans Day
  '2023-11-23', // Thanksgiving Day
  '2023-12-25', // Christmas Day

  '2024-01-01', // New Year's Day
  '2024-01-15', // Martin Luther King Jr. Day
  '2024-02-19', // Presidents Day
  '2024-05-27', // Memorial Day
  '2024-06-19', // Juneteenth
  '2024-07-04', // Independence Day
  '2024-09-02', // Labor Day
  '2024-10-14', // Columbus Day
  '2024-11-11', // Veterans Day
  '2024-11-28', // Thanksgiving Day
  '2024-12-25', // Christmas Day

  '2025-01-01', // New Year's Day
  '2025-01-20', // Martin Luther King Jr. Day
  '2025-02-17', // Presidents Day
  '2025-05-26', // Memorial Day
  '2025-06-19', // Juneteenth
  '2025-07-04', // Independence Day
  '2025-09-01', // Labor Day
  '2025-10-13', // Columbus Day
  '2025-11-11', // Veterans Day
  '2025-11-27', // Thanksgiving Day
  '2025-12-25', // Christmas Day

  '2026-01-01', // New Year's Day
  '2026-01-19', // Martin Luther King Jr. Day
  '2026-02-16', // Presidents Day
  '2026-05-25', // Memorial Day
  '2026-06-19', // Juneteenth
  '2026-07-04', // Independence Day
  '2026-09-07', // Labor Day
  '2026-10-12', // Columbus Day
  '2026-11-11', // Veterans Day
  '2026-11-26', // Thanksgiving Day
  '2026-12-25', // Christmas Day

  '2027-01-01', // New Year's Day
  '2027-01-18', // Martin Luther King Jr. Day
  '2027-02-15', // Presidents Day
  '2027-05-31', // Memorial Day
  '2027-06-19', // Juneteenth
  '2027-07-06', // Independence Day* (observed)
  '2027-09-06', // Labor Day
  '2027-10-11', // Columbus Day
  '2027-11-11', // Veterans Day
  '2027-11-25', // Thanksgiving Day
  '2027-12-25', // Christmas Day

  '2028-01-01', // New Year's Day
  '2028-01-17', // Martin Luther King Jr. Day
  '2028-02-21', // Presidents Day
  '2028-05-29', // Memorial Day
  '2028-06-19', // Juneteenth
  '2028-07-04', // Independence Day
  '2028-09-04', // Labor Day
  '2028-10-09', // Columbus Day
  '2028-11-11', // Veterans Day
  '2028-11-23', // Thanksgiving Day
  '2028-12-25', // Christmas Day
]

export const STETH_MAINNET_REFERRAL = '0xa08CeBd094fC2690671F8db65A47e5237DA389f3'
export const STETH_WITHDRAW_QUEUE_API_URL = 'https://wq-api.lido.fi/v2/request-time'

export const MULTICHAIN_SUBGRAPH_URLS: Record<BaseNetworkId | EthereumNetworkId, string> = {
  '0x1': 'https://subgraph.satsuma-prod.com/35a4cda33945/hashnote-team/multichain-eth-mainnet/api',
  '0x5': 'DoesNotExist',
  '0xAA36A7':
    'https://subgraph.satsuma-prod.com/35a4cda33945/hashnote-team/multichain-eth-sepolia/api',
  base_mainnet:
    'https://subgraph.satsuma-prod.com/35a4cda33945/hashnote-team/multichain-base-mainnet/api',
  base_sepolia:
    'https://subgraph.satsuma-prod.com/35a4cda33945/hashnote-team/multichain-base-sepolia/api',
}

/**
 * Convert's Hashnote's internal networkId format to a chainId.
 * @param networkId
 * @returns
 */
export const networkIdToChainId = (
  networkId: EthereumNetworkId | BaseNetworkId | CantoSupportedNetworkId,
) => {
  switch (networkId) {
    case 'base_sepolia':
      return 84532
    case 'base_mainnet':
      return 8453
    default:
      return parseInt(networkId)
  }
}
